<template>
  <div class="main_page">
    <!-- 路由页面 -->
    <router-view v-if="isRouterActive" v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
    </router-view>
  </div>
</template>
<script setup>
import { ref, provide, nextTick } from 'vue';
const isRouterActive = ref(true)
const reload = () => {
  isRouterActive.value = false
  nextTick(() => {
    isRouterActive.value = true
  })
}
provide('reload', {
  reload
})

</script>
<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
}

.main_page {
  min-height: 100vh;
}
</style>
