import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "amfe-flexible/index.js";


import Vant from 'vant';
import 'vant/lib/index.css';
import "./assets/css/public.less"



// createApp(App).use(store).use(router).mount('#app')

const app = createApp(App)

app.use(router)
    .use(store)
    .use(Vant)
// app.use(VantGreen);

app.mount('#app')
