import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/beauty',
    name: 'beauty',
    component: () => import('../views/beauty.vue'),
    meta: {
      title: '订单查询'
    }
  },
  {
    path: '/petOrder',
    name: 'petOrder',
    component: () => import('../views/petOrder.vue'),
    meta: {
      title: '订单查询'
    }
  },
  {
    path: '/chapter',
    name: 'chapter',
    component: () => import('../views/chapter.vue'),
    meta: {
      title: '百喜图',
      keepAlive: true
    }
  },
  {
    path: '/chapterPreview',
    name: 'chapterPreview',
    component: () => import('../views/chapterPreview.vue'),
    meta: {
      title: '百喜图预览'
    }
  },
]


const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
